import { initialState } from ".";
import {
    REQUEST_GET_DONATIONS_START,
    REQUEST_GET_DONATIONS_SUCCESS,
    REQUEST_GET_DONATIONS_FAIL,
    REQUEST_OPEN_DONATION,
    REQUEST_CLOSE_DONATION
} from "../actions/donations";

export default function reducer(state = initialState.donations, action) {
    switch (action.type) {
        case REQUEST_GET_DONATIONS_START:
            return {
                ...state,
                loading: true
            }

        case REQUEST_GET_DONATIONS_SUCCESS:
            return {
                ...state,
                items: action.donations,
                loading: false,
            }

        case REQUEST_GET_DONATIONS_FAIL:
            return {
                ...state,
                loading: false
            }

        case REQUEST_OPEN_DONATION:
            return {
                ...state,
                open: action.id
            }

        case REQUEST_CLOSE_DONATION:
            return {
                ...state,
                open: null
            }

        default:
            return state;
    }
}
