import axios from "axios";
import { API_ROOT, HEADERS } from "../constants";

export const REQUEST_GET_DONATIONS_START = "GET_DONATIONS_START";
export const REQUEST_GET_DONATIONS_SUCCESS = "GET_DONATIONS_SUCCESS";
export const REQUEST_GET_DONATIONS_FAIL = "GET_DONATIONS_FAIL";
export const REQUEST_OPEN_DONATION = "REQUEST_OPEN_DONATION";
export const REQUEST_CLOSE_DONATION = "REQUEST_CLOSE_DONATION";

export function getDonations(page, cb) {
	return function (dispatch) {

		dispatch({
			type: REQUEST_GET_DONATIONS_START
		});

		axios.get(`${API_ROOT}/donations?page=${page}`, {
			headers: HEADERS
		}).then(res => res.data).then(data => {
			dispatch({
				type: REQUEST_GET_DONATIONS_SUCCESS,
				donations: data.donations
			});
			if (cb) cb();
		}).catch(err => {
			console.error("Error getting donations: ", err);
			dispatch({
				type: REQUEST_GET_DONATIONS_FAIL
			});
		})
	}
}

export function openDonation(id) {
	return {
		type: REQUEST_OPEN_DONATION,
		id
	}
}

export function closeDonation() {
	return {
		type: REQUEST_CLOSE_DONATION,
	}
}
