import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectTile, deselectTile } from "../actions/tiles";
import { openDonation } from "../actions/donations";
import { rainbow, tiles, GRID_SIZE } from "../constants";
import { chunk } from "../helpers";

function RainbowPiece({
	slotId,
	selected,
	donation,
	tile,
	loading,
	line
}) {

	const dispatch = useDispatch();
	const history = useHistory();
	const page = useSelector(state => state.page);

	const [fillOpacity, setFillOpacity] = useState(0);
	const [tickOpacity, setTickOpacity] = useState(Number(selected) * 0.9);

	const hasMessage = donation && (donation.message || (donation.name && donation.name !== "anonymous"));
	const messageFill = `rgba(255,255,255,${hasMessage ? 0.7 : 0})`;

	useEffect(() => {
		if (Boolean(donation)) {
			setFillOpacity(1);
		}
	}, [donation]);

	const handleMouseEnter = e => {
		if (!fillOpacity) setFillOpacity(0.2);
	};

	const handleMouseLeave = e => {
		if (fillOpacity === 0.2) setFillOpacity(0);
	};

	return (
		<g
			id={`marker-${slotId}`}
			className="rainbowPiece"
			transform={tile.gTransform}
			onClick={e => {
				if (Boolean(donation)) {
					dispatch(openDonation(donation.tile));
					history.push(`/?page=${page}&donation=${donation.tile}`);
				} else {
					if (!loading) {
						setFillOpacity(selected ? 0.2 : 0.5);
						setTickOpacity(Number(!selected) * 0.9);
						dispatch(selected ? deselectTile(slotId) : selectTile(slotId));
					}
				}
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<path
				id={`Tile_Path_${slotId}`}
				data-name={`Tile Path ${slotId}`}
				d={tile.d}
				transform={tile.transform}
				fill={`rgba(${line.palette}, ${fillOpacity})`}
				stroke="#fff"
				strokeWidth="1"
			/>
			<polygon className="checkmark" fill={`rgba(255,255,255,${tickOpacity})`} points="18.4,23.8 14.6,19.7 10.4,23.8 10.4,23.8 18.3,31.7 31.6,18.4 28.5,15.3 " />
			<g style={{ transform: "translate(5px, 5px) scale(0.8)" }}>
				<path fill={messageFill} d="M23,11h-0.6c-1.7,0-3.3,0.4-4.7,1.1c-3.6,1.8-5.8,5.4-5.8,9.4c0,1.5,0.3,3,0.9,4.3l0.1,0.1l-2.6,7.8
		l7.7-2.6l0.2,0c1,0.4,2.9,0.9,4,0.9h0.3c4,0,7.6-2.2,9.4-5.8c0.7-1.5,1.1-3.1,1.1-4.7v-0.6C32.7,15.6,28.4,11.3,23,11z M31.4,21.5
		c0,1.4-0.3,2.8-0.9,4c-1.5,3-4.6,4.9-8,4.9c-1.4,0-2.8-0.3-4-0.9l-0.3-0.2l-5.3,1.8l1.8-5.3l-0.2-0.3c-0.6-1.2-0.9-2.6-0.9-4
		c0-3.4,1.9-6.5,4.9-8c1.2-0.6,2.6-0.9,4-0.9H23c4.6,0.2,8.2,3.8,8.4,8.3V21.5z"/>
				<circle fill={messageFill} cx="17.5" cy="22.8" r="1.3" />
				<circle fill={messageFill} cx="22.2" cy="22.8" r="1.3" />
				<circle fill={messageFill} cx="26.9" cy="22.8" r="1.3" />
			</g>
		</g>
	)
}

function RainbowLine({
	index,
	line,
	rowSlots = [],
	loading
}) {
	const lineNumber = index + 1;

	return (
		<g
			data-name={`Palette Group ${lineNumber}`}
			id={`Palette_Group_${lineNumber}`}
			transform={`translate(0 ${line.translate})`}
		>
			<path
				id={`Palette_Path_${lineNumber}`}
				data-name={`Palette Path ${lineNumber}`}
				d="M328.529,255.978v44a534.25,534.25,0,0,1,375-.372v-44A534.25,534.25,0,0,0,328.529,255.978Z"
				transform="translate(-328.529 -94.808)"
				fill={line.palettePale}
			/>
			{Boolean(rowSlots.length) && tiles && tiles.map((tile, index) => {
				const { id: slotId, selected, donation } = rowSlots[index] || { id: null };

				return slotId && <RainbowPiece
					key={slotId}
					slotId={slotId}
					selected={selected}
					donation={donation}
					tile={tile}
					loading={loading}
					line={line}
				/>
			})}
		</g>
	)
}

function Rainbow({ loading }) {

	const { page, donations, selected } = useSelector(state => ({
		page: state.page,
		donations: state.donations.items,
		selected: state.tiles.selected
	}));

	// Generating array of slots based on the current page
	const slots = useMemo(() => {
		let result = [];
		for (let i = (Number(page) * GRID_SIZE) + 1; i <= Number(page + 1) * GRID_SIZE; i++) {
			// Checking if slot already selected
			let isSelected = selected.includes(i);
			// Checking if slot is paid (has donation)
			let slotDonation = donations.find(d => d.tile === i);
			result.push({ id: i, selected: isSelected, donation: slotDonation })
		}
		return result;
	}, [page, selected, donations]);

	const groupedSlots = Boolean(slots.length) ? chunk(slots, 8) : [];
	let scaleUnit = (page * 56) + 1;

	// if ('undefined' !== typeof(slotFrom) || null !== slotFrom) {
	//     scaleUnit = Math.floor(slotFrom / 7);
	// }
	// const handleClick = (e, page_url) => {
	//     console.log("Successfully catched click!!!", e.target.dataset, e.target.id, page_url);
	// }

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			id='rainbow-svg'
			version='1'
			x="0"
			y="0"
			width="100%"
			viewBox="0 64 375 400"
			preserveAspectRatio="xMinYMin meet"
		>
			<g id="NHS_Florence_V3" data-name="NHS Florence V3" clipPath="url(#clip-NHS_Florence_V3)">
				<g id="Group_1" data-name="Group 11" transform="translate(-175 23)" opacity="0.203">
					<line id="Line_1-9" data-name="Line 1" y2="44" transform="translate(187.5 112.5)" fill="none" stroke="#333333" strokeWidth="1" />
					<text id="_3" data-name="3" transform="translate(188 105)" fill="#333333" fontSize="16" fontFamily="SegoeUI, Segoe UI"><tspan x="-4.313" y="0">{scaleUnit}</tspan></text>
				</g>
				<g id="Group_2" data-name="Group 2" transform="translate(-131.5 10)" opacity="0.698">
					<line id="Line_1-8" data-name="Line 1" y2="44" transform="translate(187.5 112.5)" fill="none" stroke="#333333" strokeWidth="1" />
					<text id="_4" data-name="4" transform="translate(188 105)" fill="#333333" fontSize="16" fontFamily="SegoeUI, Segoe UI"><tspan x="-4.313" y="0">{scaleUnit + 1}</tspan></text>
				</g>
				<g id="Group_3" data-name="Group 1" transform="translate(-87.5 1)">
					<line id="Line_1" data-name="Line 1" y2="44" transform="translate(187.5 112.5)" fill="none" stroke="#333333" strokeWidth="1" />
					<text id="_5" data-name="5" transform="translate(188 105)" fill="#333333" fontSize="16" fontFamily="SegoeUI, Segoe UI"><tspan x="-4.313" y="0">{scaleUnit + 2}</tspan></text>
				</g>
				<g id="Group_4" data-name="Group 3" transform="translate(-43.5 -5)">
					<line id="Line_1-2" data-name="Line 1" y2="44" transform="translate(187.5 112.5)" fill="none" stroke="#333333" strokeWidth="1" />
					<text id="_6" data-name="6" transform="translate(188 105)" fill="#333333" fontSize="16" fontFamily="SegoeUI, Segoe UI"><tspan x="-4.313" y="0">{scaleUnit + 3}</tspan></text>
				</g>
				<g id="Group_5" data-name="Group 4" transform="translate(0.5 -6)">
					<line id="Line_1-3" data-name="Line 1" y2="44" transform="translate(187.5 112.5)" fill="none" stroke="#333333" strokeWidth="1" />
					<text id="_7" data-name="7" transform="translate(188 105)" fill="#333333" fontSize="16" fontFamily="SegoeUI, Segoe UI"><tspan x="-4.313" y="0">{scaleUnit + 4}</tspan></text>
				</g>
				<g id="Group_6" data-name="Group 5" transform="translate(44.5 -5)">
					<line id="Line_1-4" data-name="Line 1" y2="44" transform="translate(187.5 112.5)" fill="none" stroke="#333333" strokeWidth="1" />
					<text id="_8" data-name="8" transform="translate(188 105)" fill="#333333" fontSize="16" fontFamily="SegoeUI, Segoe UI"><tspan x="-4.313" y="0">{scaleUnit + 5}</tspan></text>
				</g>
				<g id="Group_7" data-name="Group 6" transform="translate(88.5 1)">
					<line id="Line_1-5" data-name="Line 1" y2="44" transform="translate(187.5 112.5)" fill="none" stroke="#333333" strokeWidth="1" />
					<text id="_9" data-name="9" transform="translate(188 105)" fill="#333333" fontSize="16" fontFamily="SegoeUI, Segoe UI"><tspan x="-4.313" y="0">{scaleUnit + 6}</tspan></text>
				</g>
				<g id="Group_8" data-name="Group 7" transform="translate(132.5 10)" opacity="0.698">
					<line id="Line_1-6" data-name="Line 1" y2="44" transform="translate(187.5 112.5)" fill="none" stroke="#333333" strokeWidth="1" />
					<text id="_10" data-name="10" transform="translate(188 105)" fill="#333333" fontSize="16" fontFamily="SegoeUI, Segoe UI"><tspan x="-8.625" y="0">{scaleUnit + 7}</tspan></text>
				</g>
				<g id="Group_9" data-name="Group 8" transform="translate(176.5 23)" opacity="0.203">
					<line id="Line_1-7" data-name="Line 1" y2="44" transform="translate(187.5 112.5)" fill="none" stroke="#333333" strokeWidth="1" />
					<text id="_11" data-name="11" transform="translate(188 105)" fill="#333333" fontSize="16" fontFamily="SegoeUI, Segoe UI"><tspan x="-8.625" y="0">{scaleUnit + 8}</tspan></text>
				</g>
				{/* Beginning of repetable block */}
				{Boolean(groupedSlots.length) && rainbow && rainbow.map((line, index) => {
					return <RainbowLine
						loading={loading}
						index={index}
						key={line.palette}
						line={line}
						rowSlots={groupedSlots[index] || []}
					/>
				})}
				{/* End of repetable block */}
			</g>
		</svg>
	);
}

export default Rainbow;
