import React, { useRef, useState, useEffect } from "react";

export default function VerticalExpander({ open = false, speed = 150, children, style: additionalStyle }) {

	const innerContainer = useRef(null);

	const [height, setHeight] = useState(0);

	useEffect(() => {
		if (innerContainer.current) setHeight(innerContainer.current.offsetHeight);
	}, [children]);

	return <div
		style={{
			overflow: open ? "initial" : "hidden",
			height: open ? height : 0,
			transform: open ? "scaleY(1) translateY(0px)" : "scaleY(0.8) translateY(-5px)",
			transformOrigin: "top center",
			opacity: open ? 1 : 0,
			transition: `all ${speed / 1000}s ease`,
			pointerEvents: open ? "all" : "none",
			...additionalStyle
		}}
	>
		<div ref={innerContainer}>{children}</div>
	</div>
}
