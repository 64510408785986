export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const CLIENT_ROOT = process.env.REACT_APP_CLIENT_ROOT;
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const SUCCESS_PAYMENT = "success";
export const FAILED_PAYMENT = "fail";

export const RED = 'red';
export const ORANGE = 'orange';
export const YELLOW = 'yellow';
export const GREEN = 'green';
export const BLUE = 'blue';
export const INDIGO = 'indigo';
export const VIOLET = 'violet';
export const RAINBOW = 'rainbow';
// export const TILE = "tile";

export const GRID_SIZE = 56;

export const HEADERS = {
	'Accept': 'application/json',
	'Content-Type': 'application/json'
}

export const rainbow = [
	{
		color: RED,
		palette: '226,17,31',
		palettePale: "#f7babe",
		translate: 0
	},
	{
		color: ORANGE,
		palette: '243,107,31',
		palettePale: "#fbd2bb",
		translate: 44
	},
	{
		color: YELLOW,
		palette: '230,184,41',
		palettePale: "#f7eabe",
		translate: 88
	},
	{
		color: GREEN,
		palette: '101,178,39',
		palettePale: "#d0e8be",
		translate: 131.915
	},
	{
		color: BLUE,
		palette: '25,142,230',
		palettePale: "#bbddf7",
		translate: 175.915
	},
	{
		color: INDIGO,
		palette: '4,48,135',
		palettePale: "#b6c3dc",
		translate: 219.5
	},
	{
		color: VIOLET,
		palette: '124,31,144',
		palettePale: "#dcc4e1",
		translate: 263.5
	}
]


export const PRODUCTS = {
	[RED]: {
		'amount': 2000
	},
	[ORANGE]: {
		'amount': 2000
	},
	[YELLOW]: {
		'amount': 2000
	},
	[GREEN]: {
		'amount': 2000
	},
	[BLUE]: {
		'amount': 2000
	},
	[INDIGO]: {
		'amount': 2000
	},
	[VIOLET]: {
		'amount': 2000
	},
	[RAINBOW]: {
		'amount': 12500
	},
};

export const tiles = [
	{
		gTransform: 'translate(11.5 143.696)',
		d: 'M384.029,421.915q-22.206,5.685-44,13.3v44q21.773-7.621,44-13.3Z',
		transform: 'translate(-340.029 -421.915)'
	},
	{
		gTransform: 'translate(55.5 134.384)',
		d: 'M384.029,421.915v44q21.842-5.591,44-9.312v-44Q405.889,416.32,384.029,421.915Z',
		transform: 'translate(-384.029 -412.603)'
	},
	{
		gTransform: 'translate(99.5 128.863)',
		d: 'M428.029,412.6v44q21.9-3.68,44-5.521v-44Q449.951,408.927,428.029,412.6Z',
		transform: 'translate(-428.029 -407.082)'
	},
	{
		gTransform: 'translate(143.5 127)',
		d: 'M472.029,407.082v44q21.962-1.834,44-1.863v-44Q494.009,405.24,472.029,407.082Z',
		transform: 'translate(-472.029 -405.219)'
	},
	{
		gTransform: 'translate(187.5 127)',
		d: 'M516.029,405.219v44q22.019-.021,44,1.781V407Q538.066,405.206,516.029,405.219Z',
		transform: 'translate(-516.029 -405.219)'
	},
	{
		gTransform: 'translate(231.5 128.781)',
		d: 'M560.029,407v44q22.075,1.8,44,5.437v-44Q582.125,408.8,560.029,407Z',
		transform: 'translate(-560.029 -407)'
	},
	{
		gTransform: 'translate(275.5 134.218)',
		d: 'M604.029,456.437q22.137,3.675,44,9.225v-44q-21.844-5.547-44-9.225Z',
		transform: 'translate(-604.029 -412.437)'
	},
	{
		gTransform: 'translate(319.5 143.443)',
		d: 'M648.029,421.662v44q22.2,5.64,44,13.206v-44Q670.254,427.294,648.029,421.662Z',
		transform: 'translate(-648.029 -421.662)'
	}
]
