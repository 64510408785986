export const REQUEST_SELECT_TILE = "REQUEST_SELECT_TILE";
export const REQUEST_DESELECT_TILE = "REQUEST_DESELECT_TILE";
export const REQUEST_OPEN_TILE = "REQUEST_OPEN_TILE";
export const REQUEST_CLOSE_TILE = "REQUEST_CLOSE_TILE";

export function selectTile(id) {
    return {
        type: REQUEST_SELECT_TILE,
        id
    }
};

export function deselectTile(id) {
    return {
        type: REQUEST_DESELECT_TILE,
        id
    }
};