import { initialState } from ".";
import {
    REQUEST_SET_PAGE
} from "../actions/page";

export default function reducer(state = initialState.page, action) {
    switch(action.type) {
        case REQUEST_SET_PAGE:
            return action.page
            
        default:
            return state;
    }
}