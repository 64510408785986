import React from 'react';
import 'core-js';
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from './App';
// Redux.
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import reducers, { initialState } from "./reducers";
import reportWebVitals from './reportWebVitals';
import { ModalProvider } from "../src/components/Modal";

// Init the redux store.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    initialState,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);

render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <ModalProvider>
                    <App />
                </ModalProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
