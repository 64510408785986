import React from 'react';
import { useSelector } from 'react-redux';
import { currencyFormatter } from '../helpers';
import logo from '../media/nightingale-logo.svg';

function Header() {

	const totalRaised = useSelector(state => state.totalRaised.amount);

	return <>
		<div className="total_raised_wrap">
			<p className="total_raised">{currencyFormatter(totalRaised / 100)}</p>
			<p>Raised so far</p>
		</div>
		<div>
            Choose your Rainbow Tile to leave your message of thanks.
		</div>
        <br />
        <div>
            Tiles cost £20 each and help fund local healthcare charities. Only one message can be left per donation.
        </div>
        <div className="header__title">
			<img src={logo} alt="Nightingale's Rainbow" />
		</div>
	</>
}

export default Header;
