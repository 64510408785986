import React, { useEffect, useRef, useState, useMemo } from 'react';
import axios from 'axios';
import clsx from 'clsx'
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { setPage } from "./actions/page";
import { getDonations, closeDonation } from "./actions/donations";
import Modal, { SMALL } from "./components/Modal";
import { fb, twitter, email, copy } from "./icons";
// import loadingSvg from './loading.svg';
import {
	STRIPE_PUBLISHABLE_KEY,
	SUCCESS_PAYMENT,
	FAILED_PAYMENT,
	API_ROOT,
	rainbow
} from './constants';
import Header from './partials/Header';
import Rainbow from './partials/Rainbow';
import { currencyFormatter, tilesToProducts, parseQueryString, normalizeTileNumber, getTileColor } from './helpers';
import VerticalExpander from './components/VerticalExpander';
import leftNav from './left.svg';
import rightNav from './right.svg';
import './app.scss';
import { getTotalRaised } from './actions/totalRaised';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const initialQuery = parseQueryString(window.location.search);

const formInitialValues = {
	address: '',
	fullName: '',
	giftAid: false,
	giftAidName: '',
	message: '',
	postCode: '',
	rainbowPieces: {},
}

function App() {

	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	const firstInput = useRef(null);

	const {
		page,
		highestPage,
		maxAllowedPage,
		selectedTiles,
		displayedDonation,
		donations
	} = useSelector(state => ({
		page: state.page,
		highestPage: state.totalRaised.highestPage,
		maxAllowedPage: state.totalRaised.maxAllowedPage,
		selectedTiles: state.tiles.selected,
		displayedDonation: state.donations.open,
		donations: state.donations.items
	}));

	const [submitting, setSubmitting] = useState(false);
	const [loading, setLoading] = useState(true);
	const [values, setValues] = useState(formInitialValues);

	// Modal's state
	const [rainbowModal, setRainbowModal] = useState(!!initialQuery.page);
	const [giftAidModal, setGiftAidModal] = useState(false);
	const [paymentFailed, setPaymentFailed] = useState(false);
	const [paymentMade, setPaymentMade] = useState(false);
	const [showDonation, setShowDonation] = useState(null);
	const [isNextPageDisabled, setIsNextPageDisabled] = useState(false);
	const [isPreviousPageDisabled, setIsPreviousPageDisabled] = useState(false);
	const [isDisabledNavigationButtonClicked, setIsDisabledNavigationButtonClicked] = useState(false);

	const hasSelectedTiles = Boolean(selectedTiles.length);

	const query = useMemo(() => {
		return parseQueryString(location.search)
	}, [location.search]);

	const products = useMemo(() => {
		return tilesToProducts(selectedTiles)
	}, [selectedTiles]);

	const donationAmount = useMemo(() => {
		return products.reduce((acc, cur) => {
			return acc + (cur.amount * cur.quantity)
		}, 0)
	}, [products]);

	useEffect(() => {
		if (!rainbowModal && highestPage) {
			const currentPage = highestPage > maxAllowedPage ? maxAllowedPage : highestPage;
			dispatch(setPage(currentPage));
		}
	}, [dispatch, highestPage, maxAllowedPage, rainbowModal]);

	useEffect(() => {
		dispatch(getTotalRaised());
		function getTotal() {
			dispatch(getTotalRaised());
		}
		const interval = setInterval((getTotal, 60000));
		return () => {
			clearInterval(interval);
		}
	}, [dispatch]);

	useEffect(() => {
		// Getting list of donations based on the current page
		let interval;
		let timeout = setTimeout(() => {
			function get() {
				dispatch(getDonations(page, () => setLoading(false)));
			}
			get();
			interval = setInterval(get, 60000);
		}, 300);
		// Cleanup
		return () => {
			if (timeout) clearTimeout(timeout);
			if (interval) clearInterval(interval);
		}
	}, [dispatch, page]);

	useEffect(() => {
		// Showing donation message if got one
		if (donations && donations.length && displayedDonation) {
			let match = donations.find(d => d.tile === displayedDonation);
			if (match) {
				setShowDonation(match)
			}
		}
	}, [donations, displayedDonation, dispatch, page]);

	useEffect(() => {
		// Show payment successful modal
		if (query && query.payment && query.payment === SUCCESS_PAYMENT) {
			if (!paymentMade) setPaymentMade(true)
		}
	}, [query, page, paymentMade]);

	useEffect(() => {
		if (query && query.payment && query.payment === FAILED_PAYMENT) {
			// Show payment failed modal
			if (!paymentFailed) setPaymentFailed(true)
		}
	}, [query, page, paymentFailed]);

	useEffect(() => {
		// Make navigation buttons enabled/disabled.
		// Next
		if (page >= highestPage || page >= maxAllowedPage) {
			setIsNextPageDisabled(true)
		} else {
			setIsNextPageDisabled(false)
		}
		// Previous
		if (page <= 0) {
			setIsPreviousPageDisabled(true)
		} else {
			setIsPreviousPageDisabled(false)
		}
	}, [highestPage, maxAllowedPage, page]);

	const handleInputChange = (event) => {
		const target = event.target;
		const value = ('checkbox' === target.type) ? target.checked : target.value;
		setValues((values) => ({
			...values,
			[target.name]: value,
		}));
	};

	const handlePageChange = (newPage) => {
		// Block requests
		if (!loading) setLoading(true);
		// Updating redux and url
		dispatch(setPage(newPage));
		history.push(`/?page=${newPage}`)
	};

	return (
		<>
			<div style={{
				maxWidth: '1140px',
				margin: "0 auto",
				boxSizing: "border-box",
				textAlign: "center"
			}}>
				<div
					style={{
						cursor: "pointer",
						backgroundColor: "#00703c",
						color: 'white',
						fontSize: '1.3rem',
						display: "inline-block",
						padding: "20px 30px"
					}}
					onClick={() => {
						setRainbowModal(true);
						history.push(`/?page=${page}`);
					}}
				>
					<b>Dedicate a Rainbow Tile</b>
				</div>
			</div>
			<Modal
				modalClass=" with-border-radius"
				open={rainbowModal}
				onOuterClick={() => {
					history.push('/');
					setRainbowModal(false);
				}}
				onClose={() => {
					history.push('/');
					setRainbowModal(false);
				}}
			>
				<div id="rainbow_modal">
					<div id="rainbow_app">
						<div className="app_wrapper">

							<div className="rainbow_wrapper">
								<Rainbow loading={loading} />
							</div>

							<VerticalExpander open={!hasSelectedTiles}>
								{/* Total Raised and branding */}
								<Header />
							</VerticalExpander>

							{/* Form for donations */}
							<VerticalExpander open={hasSelectedTiles}>
								<div className="form-container">
									<h3 className="form-header">
										Your Message
									</h3>
									<form className="donation-form" onSubmit={e => {
										e.preventDefault();
										// Blocking request
										setSubmitting(true);
										// Getting stripe session
										axios.post(`${API_ROOT}/stripe-session`, {
											tiles: selectedTiles,
											name: values.fullName,
											message: values.message,
											address: values.address,
											postCode: values.postCode,
											giftAid: values.giftAid,
											public: true,
											giftAidName: values.giftAidName
										}).then(res => res.data).then(async res => {
											const stripe = await stripePromise;
											// Making payment
											await stripe.redirectToCheckout({
												sessionId: res.id
											}).catch(err => {
												console.log("Payment error:", err);
											}).finally(() => {
												setSubmitting(false);
											});
										}).catch(err => {
											console.log("Error creating stripe session: ", err);
											// Showing payment failed modal
											history.push(`/?page=${page}&payment=${FAILED_PAYMENT}`)
											setSubmitting(false);
										})
									}}>
										{/* Uncomment the next line to show the success message */}
										<input
											ref={firstInput}
											autoFocus={hasSelectedTiles}
											id="full-name"
											className="form-field full-name"
											type="text"
											placeholder="Your name (or anonymous)"
											name="fullName"
											value={values.fullName}
											onChange={handleInputChange}
											autoComplete="off"
										/>
										{/* Uncomment the next line to show the error message */}
										{/* <span id="full-name-error">Please enter a first name</span> */}
										<textarea
											id="message"
											className="form-field"
											type="text"
											placeholder="Leave your Virtual Rainbow Tile dedication here (or leave blank if you do not wish to leave a public message)"
											name="message"
											onChange={handleInputChange}
											value={values.message}
											autoComplete="off"
										>
										</textarea>
										<label className="checkbox-label" htmlFor="gift-aid">
											<input
												id="gift-aid"
												type="checkbox"
												name="giftAid"
												checked={values.giftAid}
												// onChange={(e) => { showGiftAidModal(e); handleInputChange(e) }}
												onChange={e => {
													setGiftAidModal(true)
													handleInputChange(e)
												}}
											/> Make my donation a Gift Aid donation
										</label>
										<VerticalExpander open={!!values.giftAid}>
											<input
												id="gift-aid-name"
												name="giftAidName"
												placeholder="Your name"
												className="form-field"
												type="text"
												value={values.giftAid ? values.giftAidName : ""}
												onChange={handleInputChange}
												required={!!values.giftAid}
											/>
											<textarea
												id="address"
												className="form-field"
												placeholder="Your address"
												name="address"
												onChange={handleInputChange}
												value={values.giftAid ? values.address : ""}
												required={!!values.giftAid}
											></textarea>
											<input
												id="post-code"
												className="form-field full-name"
												type="text"
												placeholder="Your postcode"
												name="postCode"
												value={values.giftAid ? values.postCode : ""}
												onChange={handleInputChange}
												required={!!values.giftAid}
											/>
										</VerticalExpander>
										<label className="checkbox-label" htmlFor="marketing-consent">
											<input
												id="marketing-consent"
												required
												type="checkbox"
											/> I accept the <a href="/terms" target="_blank">Terms &amp; Conditions</a>
										</label>
										<button
											className="form-field submit"
											type="submit"
											disabled={submitting || !products.length}
										>
											Complete donation <b>{currencyFormatter(donationAmount / 100)}</b>
										</button>
									</form>
								</div>
							</VerticalExpander>

							{/* <RecentDonations recent={recentDonations} /> */}
						</div>
					</div>
					<div onClick={e => {
						if (!isPreviousPageDisabled) {
							let previousPage = page - 1;
							handlePageChange(previousPage)
						} else {
							setIsDisabledNavigationButtonClicked(true);
						}
					}}
						className={clsx('nav_button', isPreviousPageDisabled && 'disabled')}
						id="leftNav"
					>
						<img src={leftNav} alt="" />
					</div>

					<div onClick={e => {
						if (!isNextPageDisabled) {
							let nextPage = page + 1;
							handlePageChange(nextPage)
						} else {
							setIsDisabledNavigationButtonClicked(true);
						}
					}}
						className={clsx('nav_button', isNextPageDisabled && 'disabled')}
						id="rightNav"
					>
						<img src={rightNav} alt="" />
					</div>
				</div>
			</Modal>
			<Modal
				open={Boolean(values.giftAid && giftAidModal)}
				onOuterClick={() => {
					history.push(`/?page=${page}`);
					setGiftAidModal(false);
				}}
				onClose={() => {
					history.push(`/?page=${page}`);
					setGiftAidModal(false);
				}}
			>
				<Modal.Content>
					<p className="mb-1">Gift Aid is a government scheme for UK charities to reclaim the tax you have paid and gain 25% more at no cost or hassle to you.</p>
					<p className="mb-1">By ticking this box you agree with the statement "I'm a UK taxpayer and I understand that if the Gift Aid claimed on this donation is more than the tax I pay in the current tax year (on my income and/or capital gains), it's my responsibility to pay the difference."</p>
					<p className="mb-1">Gift Aid is reclaimed by the charity from the tax you pay for the current tax year. Your name and address are needed to identify you as a current UK taxpayer.</p>
					<p className="mb-1">Please note we can only claim Gift Aid on behalf of individuals giving their own money. Money given on behalf of organisations or groups of people cannot be gift aided.</p>
				</Modal.Content>
			</Modal>
			<Modal
				width={SMALL}
				open={paymentFailed}
				onOuterClick={() => {
					history.push(`/?page=${page}`);
					setPaymentFailed(false)
				}}
				onClose={() => {
					history.push(`/?page=${page}`);
					setPaymentFailed(false)
				}}
			>
				<Modal.Content>
					<h2 style={{ textAlign: "center", marginTop: 0 }}>Payment failed.</h2>
					<p>Error while processing donation.</p>
				</Modal.Content>
			</Modal>
			<Modal
				width={SMALL}
				open={Boolean(showDonation)}
				onOuterClick={() => {
					history.push(`/?page=${page}`);
					setShowDonation(null);
					if (displayedDonation) dispatch(closeDonation());
					if (paymentMade) setPaymentMade(false);
				}}
				onClose={() => {
					history.push(`/?page=${page}`);
					setShowDonation(null);
					if (displayedDonation) dispatch(closeDonation());
					if (paymentMade) setPaymentMade(false);
				}}
			>
				<Modal.Content>
					<h2
						style={{ textAlign: "center", marginTop: 0 }}
					>
						{showDonation && showDonation.name ? showDonation.name : "Anonymous donation"}
					</h2>
					{paymentMade && <>
						<div style={{
							textAlign: "center"
						}}>Thank you for supporting Nightingale's Rainbow!</div>
						<hr />
					</>}
					{(showDonation && showDonation.message) && <>
						{paymentMade && <p>Your message:</p>}
						<p style={{ whiteSpace: "pre-line" }}>{showDonation.message}</p>
						<hr />
					</>}
					<h4 style={{ textAlign: "center", marginBottom: 0 }}>Share this message</h4>
					<div style={{
						display: "flex",
						justifyContent: "space-evenly",
						alignItems: "center",
						flexWrap: "wrap",
						marginTop: 20
					}}>
						<a
							href={`mailto:?body=${encodeURIComponent(window.location.href.replace("&payment=success", ""))}&subject=${encodeURIComponent("I’ve dedicated a Rainbow Tile on Nightingale’s Rainbow to say thank you")}`}
							style={{
								display: "inline-block",
								cursor: "pointer"
							}}
						>
							<img
								src={email}
								alt="email icon"
								style={{
									width: '50px'
								}}
							/>
						</a>
						<a
							href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href.replace("&payment=success", ""))}&quote=${encodeURIComponent('I’ve dedicated a Rainbow Tile on Nightingale’s Rainbow to say thank you')}`}
							style={{
								display: "inline-block",
								cursor: "pointer"
							}}
						>
							<img
								src={fb}
								alt="facebook"
								style={{
									width: '50px'
								}}
							/>
						</a>
						<a
							href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href.replace("&payment=success", ""))}&text=${encodeURIComponent("I’ve dedicated a Rainbow Tile on @bucks_rainbow to say thank you")}.`}
							style={{
								display: "inline-block",
								cursor: "pointer"
							}}
						>
							<img
								src={twitter}
								alt="twitter"
								style={{
									width: '50px'
								}}
							/>
						</a>
						<div
							style={{
								display: "inline-block",
								cursor: "pointer",
								width: "50px",
								height: "50px",
								backgroundColor: "#65b227",
								borderRadius: "5px",
								padding: "11px",
								boxSizing: 'border-box'
							}}
							onClick={() => {
								// Copy to clipboard
								const el = document.createElement('textarea');
								el.value = window.location.href.replace("&payment=success", "");
								el.setAttribute('readonly', '');
								el.style.position = 'absolute';
								el.style.left = '-9999px';
								document.body.appendChild(el);
								el.select();
								document.execCommand('copy');
								document.body.removeChild(el);
							}}
						>
							<img src={copy} alt="copy to clipboard" style={{ width: "28px" }} />
						</div>
					</div>
					<hr />
					{showDonation && <div style={{ textAlign: "center" }}>
						<TileNumber tile={showDonation.tile} />
					</div>}
				</Modal.Content>
			</Modal>
			{/* Navigation failure modal */}
			<Modal
				width={SMALL}
				open={isDisabledNavigationButtonClicked}
				onOuterClick={() => {
					history.push(`/?page=${page}`);
					setIsDisabledNavigationButtonClicked(false);
				}}
				onClose={() => {
					history.push(`/?page=${page}`);
					setIsDisabledNavigationButtonClicked(false);
				}}
			>
				<Modal.Content
					style={{
						backgroundColor: "#ffe7e7",
					}}
				>
					{
						page >= maxAllowedPage
							?
							<p>
								There's loads of free space on the rainbow before this page,
								please go back and click on any of the free tiles to donate.
							</p>
							:
							<p>
								There aren't any donations past this page yet. Tap a free tile and
								make a donation here to help us fill the rainbow!
							</p>
					}
				</Modal.Content>
			</Modal>
		</>
	);
}

function TileNumber({ tile }) {

	const normalized = normalizeTileNumber(tile);
	const colorName = getTileColor(normalized);
	const colorRgb = rainbow.find(item => item.color === colorName).palette;

	return <div className="v-middle" style={{
		display: "inline-block",
		boxShadow: "0px 1px 2px #0000005c",
		padding: "5px 10px 5px 5px",
		border: "2px solid #f4f4f4",
		borderRadius: 5,
	}}>
		<div
			className="mini-tile-display"
			style={{
				backgroundColor: `rgb(${colorRgb})`,
				height: 25,
				width: 25,
				borderRadius: 5,
				marginRight: 5
			}}
		/>
		<span style={{
			textTransform: "capitalize",
			fontSize: "0.9rem",
			fontWeight: "bold"
		}}>
			{colorName} {tile}
		</span>
	</div>
}

export default App;
