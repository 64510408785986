import { combineReducers } from "redux";
import initialState from "./initialState";
import donations from "./donations";
import totalRaised from "./totalRaised";
import page from "./page";
import tiles from "./tiles";

export { initialState };

export default combineReducers({
    page,
    donations,
    tiles,
    totalRaised
});
