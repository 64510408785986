import {
	GRID_SIZE,
	RED,
	ORANGE,
	YELLOW,
	GREEN,
	BLUE,
	INDIGO,
	VIOLET,
	RAINBOW,
	PRODUCTS
} from "./constants";
import numeral from "numeral";
import "numeral/locales/en-gb";
numeral.locale("en-gb");
numeral.defaultFormat("$0,0");

const ridChars = "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export function rid(length = 20, charset = ridChars) {
	let id = [];
	for (let i = 0; i < length; i++) {
		id.push(charset[Math.round(Math.random() * (charset.length - 1))]);
	}
	return id.join("");
}

export function chunk(arr, chunkSize) {
	return arr.map((e, i) => {
		return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
	}).filter(e => { return e; });
}

export function currencyFormatter(amount) {
	amount = Number(amount) || 0;
	return numeral(amount).format();
}

export function parseQueryString(qs = "") {
	const result = {}
	qs.replace(/^\?/, "")
		.split("&")
		.map(pair => pair.split("="))
		.forEach(([key, value]) => {
			if (value)
				result[decodeURIComponent(key)] = decodeURIComponent(value);
		});
	return result;
}

export function normalizeTileNumber(tileNumber) {
	let page = Math.floor((tileNumber - 1) / GRID_SIZE);
	return tileNumber - (page * GRID_SIZE) - 1;
}

export function getTileColor(tileNumber) {
	return TILE_COLORS[Math.floor(tileNumber / 8)];
}

/**
 * Takes an array of tile numbers, and returns a stripe-friendly array of
 * products with quantities. If an entire column of tiles is passed, it 
 * will replace those tiles with a 'rainbow' product.
 * 
 * @param {Number[]} tileNumbers Array of tile numbers.
 * @param {Number} page The current page of the UI.
 */
export function tilesToProducts(tileNumbers) {
	// Eventual result.
	const items = [];

	function getRainbowTiles(tileNumber) {
		const tiles = [tileNumber];
		const normalized = normalizeTileNumber(tileNumber);
		if (normalized >= 8) return [];
		for (let i = 1; i < 7; i++) {
			const nextTile = tileNumber + (i * 8);
			if (!tileNumbers.includes(nextTile)) {
				return [];
			} else {
				tiles.push(nextTile);
			}
		}
		return tiles;
	}

	// Check for rainbows.
	const rainbows = tileNumbers
		.map(tileNumber => getRainbowTiles(tileNumber))
		.filter(rainbow => rainbow.length);
	const rainbowTiles = rainbows.flat();

	// Iterate the tiles, getting the right product and quantity.
	tileNumbers.forEach(tileNumber => {
		// Skip if part of a rainbow.
		if (rainbowTiles.includes(tileNumber)) return;
		// Normalize.
		const normalized = normalizeTileNumber(tileNumber);
		const product = getTileColor(normalized);
		const existingLineItem = items.find(lineItem => lineItem.price === product);
		if (existingLineItem) {
			existingLineItem.quantity++;
		} else {
			items.push({
				price: product,
				quantity: 1
			});
		}
	});

	// Add the rainbows.
	if (rainbows.length) {
		items.push({
			price: RAINBOW,
			quantity: rainbows.length
		});
	}

	// Replacing colors with priceId
	return items.map(item => ({ ...PRODUCTS[item.price], quantity: item.quantity }));

}

const TILE_COLORS = [
	RED,
	ORANGE,
	YELLOW,
	GREEN,
	BLUE,
	INDIGO,
	VIOLET
]