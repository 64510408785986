import { initialState } from ".";
import {
    REQUEST_GET_TOTAL_RAISED_START,
    REQUEST_GET_TOTAL_RAISED_SUCCESS,
    REQUEST_GET_TOTAL_RAISED_FAIL,
} from "../actions/totalRaised";

export default function reducer(state = initialState.totalRaised, action) {
    switch (action.type) {
        case REQUEST_GET_TOTAL_RAISED_START:
            return {
                ...state,
                loading: true
            }

        case REQUEST_GET_TOTAL_RAISED_SUCCESS:
            return {
                ...state,
                amount: action.totalRaised,
				highestPage: action.highestPage,
				maxAllowedPage: action.maxAllowedPage,
                loading: false,
            }

        case REQUEST_GET_TOTAL_RAISED_FAIL:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}
