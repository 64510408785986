import { initialState } from ".";
import {
    REQUEST_SELECT_TILE,
    REQUEST_DESELECT_TILE
} from "../actions/tiles";

export default function reducer(state = initialState.tiles, action) {
    switch (action.type) {
        case REQUEST_SELECT_TILE:
            return {
                ...state,
                selected: [...state.selected, action.id]
            }

        case REQUEST_DESELECT_TILE:
            return {
                ...state,
                selected: [...state.selected].filter(s => s !== action.id)
            }

        default:
            return state;

    }
}

