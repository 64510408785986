import { parseQueryString } from "../helpers";

const query = parseQueryString(window.location.search);

const initialState = {
    page: (query.page && Number(query.page)) ? Number(query.page) : 0,
    donations: {
        items: [],
        open: (query.donation && Number(query.donation) ? Number(query.donation) : null),
        loading: false
    },
    totalRaised: {
        amount: 0,
		highestPage: 0,
		maxAllowedPage: 0,
        loading: false
    },
    tiles: {
        selected: []
    }
}

export default initialState;
