import axios from "axios";
import { API_ROOT, HEADERS } from "../constants";

export const REQUEST_GET_TOTAL_RAISED_START = "REQUEST_GET_TOTAL_RAISED_START";
export const REQUEST_GET_TOTAL_RAISED_SUCCESS = "REQUEST_GET_TOTAL_RAISED_SUCCESS";
export const REQUEST_GET_TOTAL_RAISED_FAIL = "REQUEST_GET_TOTAL_RAISED_FAIL";

export function getTotalRaised() {
    return function (dispatch, getState) {
        // const { session: { token } } = getState();

        dispatch({
            type: REQUEST_GET_TOTAL_RAISED_START
        });

        axios.get(`${API_ROOT}/total`, {
            headers: HEADERS
        })
            .then(res => res.data)
            .then(response => {
                // console.log("AXIOS RESPONSE::RAISED", response.total_donation);
                dispatch({
                    totalRaised: response.total_donation,
					highestPage: response.highestPage,
					maxAllowedPage: response.maxAllowedPage,
                    type: REQUEST_GET_TOTAL_RAISED_SUCCESS
                });
            }).catch(err => {
                console.error("Error getting config: ", err);
                dispatch({
                    type: REQUEST_GET_TOTAL_RAISED_FAIL
                });
            });
    }
}
