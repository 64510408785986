import React, { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { rid } from "../helpers";
import { close } from "../icons";
import "./modal.scss";

const modalSpeed = 300;

const ModalContext = React.createContext();

export function ModalProvider({ children }) {

    const [root, setRoot] = useState(null);
    const [id] = useState("modal-target-" + rid(20));

    const [hasOpenModals, setHasOpenModals] = useState(false);

    const openModals = useRef(0);

    useEffect(() => {
        const root = document.createElement("div");
        root.id = id;
        root.style["z-index"] = 99;
        document.body.appendChild(root);
        setRoot(root);
        return () => {
            document.body.removeChild(root);
        }
    }, [id]);

    // If there are open modals, this makes the body not scrollable.
    useEffect(() => {
        if (hasOpenModals) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "initial";
        }
    }, [hasOpenModals]);

    // Notify functions used so the context can keep track of the open modals.
    function notifyOpen() {
        if (openModals.current++ === 0) {
            setHasOpenModals(true);
        }
    }
    function notifyClosed() {
        if (--openModals.current === 0) {
            setHasOpenModals(false);
        }
    }

    return <ModalContext.Provider value={{
        root,
        notifyOpen,
        notifyClosed
    }}>
        {children}
    </ModalContext.Provider>
}

// Export standard modal sizes.
export const MEDIUM = "800px",
    SMALL = "450px",
    LARGE = "1200px";

export default function Modal({
    children,
    open = false,
    width = MEDIUM,
    closeButton,
    onOuterClick,
    onClose,
    modalClass = "",
    modalStyles = {}
}) {

    const { root, notifyOpen, notifyClosed } = useContext(ModalContext);
    const [target, setTarget] = useState(null);
    const [ready, setReady] = useState(false);

    // Add our render target to the modal DOM root.
    useEffect(() => {
        if (!root) return;
        const target = document.createElement("div");
        setTarget(target);
    }, [root]);

    useEffect(() => {
        let interval;
        if (!ready) interval = setTimeout(() => {
            setReady(true);
        }, 1000);
        return () => {
            clearTimeout(interval);
        }
    }, [ready]);

    if (!target) return null;

    return createPortal(
        <TransitionGroup>
            {(open && ready) ? <CSSTransition
                key={"content"}
                classNames="modal-fade"
                timeout={modalSpeed}
                onEnter={e => {
                    notifyOpen();
                    root.appendChild(target);
                }}
                onExited={e => {
                    notifyClosed();
                    root.removeChild(target);
                }}
            >
                <div className="modal-dimmer" onClick={e => {
                    e.stopPropagation();
                    if (onOuterClick) onOuterClick(e);
                }}>
                    <div
                        className={"modal-inner" + modalClass}
                        style={{
                            width: "100%",
                            maxWidth: width
                        }}
                        onClick={e => e.stopPropagation()}
                    >
                        {Boolean(onClose && !closeButton) && <div className="close-btn">
                            <img
                                style={{
                                    width: '20px'
                                }}
                                alt="Close modal"
                                src={close}
                                onClick={onClose}
                            />
                        </div>}
                        {Boolean(onClose && closeButton) && closeButton()}
                        {children}
                    </div>
                </div>
            </CSSTransition> : null}
        </TransitionGroup>,
        target
    );
}

Modal.Content = function ({ children, style = {}, ...props }) {
    return <div {...props} className="modal-content" style={{
        fontFamily: "'Raleway', sans-serif",
        padding: '16px',
        margin: '16px',
        borderRadius: '4px',
        backgroundColor: "#ffffff",
        ...style
    }}>{children}</div>
}